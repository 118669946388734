import React from 'react'

import Button from '../../components/Button'

import styles from './services.module.scss'

const Web = () => {
  const className = [styles.section, styles.webDesign].join(' ')
  const yearsExperience = (new Date().getFullYear()) - 2007
  return (
    <section className={className}>
      <div className={styles.sectionText}>
        <div className={styles.sectionCount}>1</div>
        <h2>Websites</h2>
        <p>I make beautiful user friendly websites.</p>
        <p>
          {yearsExperience} years of experience building corporate, e-commerce and marketing
          websites. Solve business problems and help define online strategy.
        </p>
        <Button to="/works/web/">All Websites</Button>
      </div>
      <div className={styles.webIllustration}>
        <div className={styles.mac} />
        <div className={styles.pen} />
        <div className={styles.pencil} />
      </div>
    </section>
  )
}

export default Web
