import React from 'react'
import styles from './preview.module.scss'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Image from '../Image'

const Phone = (props) => {
  const className = props.className + ' ' + styles.phone + ( (props.isDark)? ' ' + styles.dark : '' )
  return (
    <div className={className}>
      <div className={styles.phoneWrap}>
        <div className={styles.image}>
        {props.fluid ?
          <Img
            fluid={props.fluid}
            alt={props.alt}
            /> :
          <Image
            src={props.src}
            alt={props.alt}
            width={props.width}
            />
        }
        </div>
      </div>
    </div>
  )
}

Phone.propTypes = {
  src: PropTypes.string,
  fluid: PropTypes.object,
  alt: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
  isDark: PropTypes.bool,
}

export default Phone
