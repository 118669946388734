import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/Layout'

import Web from './_Web'
import Identity from './_Identity'
import ResponsiveDesign from './_ResponsiveDesign'
import Development from './_Development'

const Services = ({ location }) => {
  return (
    <Layout location={location}>
      <Helmet title="Services" />
      <Web />
      <Identity />
      <ResponsiveDesign />
      <Development />
    </Layout>
  )
}

export default Services
