import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Grid, Row, Col } from 'react-flexbox-grid'

import findImage from '../../utils/findImage'
import Tablet from '../../components/Preview/Tablet'
import Phone from '../../components/Preview/Phone'

import styles from './services.module.scss'


const ResponsiveDesign = () => {
  const query = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: { relativePath: { regex: "^/sol-noctis/i/" } }) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  )
  const images = query.images.edges

  const className = [styles.section, styles.responsive].join(' ')
  return (
    <section className={className}>
      <div className={styles.sectionText}>
        <div className={styles.sectionCount}>3</div>
        <h2>Responsive Design</h2>
        <p>
          All my websites are adapting to the device they run in. <br />
          Aim for app-like experience and using mobile gestures when possible.
        </p>
      </div>
      <div className={styles.responsiveWorks}>
        <Grid>
          <Row bottom="xs">
            <Col xs={9} className={styles.tablet}>
              <Tablet fluid={findImage(images, 'sol-noctis-home')} isDark />
            </Col>
            <Col xs={3} className={styles.phone}>
              <Phone fluid={findImage(images, 'sol-noctis-home-mobile')} isDark />
            </Col>
          </Row>
        </Grid>
      </div>
    </section>
  )
}

export default ResponsiveDesign
