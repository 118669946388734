import React from 'react'

import Image from '../../components/Image'

import styles from './services.module.scss'

import cssLogo from './img/tech/css.svg'
import gatsbyjsLogo from './img/tech/gatsbyjs.svg'
import gitLogo from './img/tech/git.svg'
import htmlLogo from './img/tech/html.svg'
import jekyllLogo from './img/tech/jekyll.svg'
import jsLogo from './img/tech/js.svg'
import postmanLogo from './img/tech/postman.svg'
import reactLogo from './img/tech/react.svg'
import sequelproLogo from './img/tech/sequelpro.png'
import vscodeLogo from './img/tech/vscode.svg'
import webpackLogo from './img/tech/webpack.svg'
import wordpressLogo from './img/tech/wordpress.svg'


const Development = () => {
  const className = [styles.section, styles.development].join(' ')
  return (
    <section className={className}>
      <div className={styles.sectionText}>
        <div className={styles.sectionCount}>4</div>
        <h2>Web Development</h2>
        <p>
          Create delightful and fast experiences using the modern technology. My
          websites load fast, work smoothly without bugs and play well with the
          search bots.
        </p>
      </div>
      <TechStack />
    </section>
  )
}

const technologies = [
  {
    title: 'HTML5',
    image: htmlLogo,
    color: '#FFDCCD',
  },
  {
    title: 'CSS3',
    image: cssLogo,
    color: '#CDDCFF',
  },
  {
    title: 'JavaScript',
    image: jsLogo,
    color: '#D9F4D4',
  },
  {
    title: 'React',
    image: reactLogo,
    color: '#DDF8FF',
  },
  {
    title: 'Webpack',
    image: webpackLogo,
    color: '#C4EBFF',
  },
  {
    title: 'Gatsby',
    image: gatsbyjsLogo,
    color: '#EBD9FD',
  },
  {
    title: 'WordPress',
    image: wordpressLogo,
    color: '#C7F1FF',
  },
  {
    title: 'Jekyll',
    image: jekyllLogo,
    color: '#FFCDCD',
  },
  {
    title: 'GitHub',
    image: gitLogo,
    color: '#E3DEFF',
  },
  {
    title: 'Sequel Pro',
    image: sequelproLogo,
    color: '#FFED9D',
  },
  {
    title: 'Postman',
    image: postmanLogo,
    color: '#FFE1D6',
  },
  {
    title: 'Visual Studio Code',
    image: vscodeLogo,
    color: '#D2ECFF',
  },
]

const TechStack = () => {
  return (
    <ul className={styles.technologies}>
      {technologies.map((item, index) => {
        return (
          <li key={index} style={{background: item.color}}>
            <div className={styles.techLogo}>
              <Image src={item.image} alt={item.title} />
            </div>
            <div className={styles.techTitle}>{item.title}</div>
          </li>
        )
      })}
    </ul>
  )
}

export default Development
